<template>
  <div
    class="h-screen flex w-full items-center justify-center"
    style="background-color: white"
  >
    <div
      :class="getDentalVersionActive ? 'bg-image-dental' : 'bg-image-kuyum'"
      class="bg-image vx-col hidden md:block lg:w-8/12 md:w-6/12 p-8 h-full"
    >
      <div class="w-full h-full flex items-center justify-center">
        <div class="w-full text-center">
          <p style="font-size: 22px" class="w-full text-white mb-4">
            {{ $t("Login_Title_1") }}
          </p>
          <p
            style="font-size: 36px; font-weight: 600"
            v-if="!getDentalVersionActive"
            class="w-full text-white"
          >
            {{ $t("Login_Title_2") }}
          </p>

          <p
            style="font-size: 36px; font-weight: 600"
            v-else
            class="w-full text-white"
          >
            {{ $t("Login_Title_3") }}
          </p>
        </div>
      </div>
    </div>
    <div class="sm:w-full lg:w-4/12 md:w-6/12">
      <div class="flex justify-center">
        <div class="p-12">
          <div class="mb-4">
            <img
              v-if="!getDentalVersionActive"
              src="@/assets/images/pages/novafab-logo.png"
              alt=""
            />
            <img v-else src="@/assets/images/pages/dentafab-logo.png" alt="" />
          </div>

          <div>
            <vs-input
              color="dark"
              :danger="userNameControl"
              name="email"
              icon-no-border
              icon="icon icon-user"
              icon-pack="feather"
              :label-placeholder="$t('Email')"
              v-model="data.username"
              class="w-full"
              @keyup.enter.native="sendLoginRequest"
            />

            <vs-input
              color="dark"
              :danger="userPasswordControl"
              type="password"
              name="password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              :label-placeholder="$t('Password')"
              v-model="data.password"
              class="w-full mt-6"
              @keyup.enter.native="sendLoginRequest"
            />
            <vs-alert class="mt-1" :active="authError" color="danger">{{
              errorMessage
            }}</vs-alert>

            <vs-button
              color="dark"
              class="w-full mb-4 mt-4"
              @click="sendLoginRequest"
              >{{ $t("Login") }}</vs-button
            >
            <div>
              <router-link
                style="color: black"
                class="float-right"
                to="/register"
                >{{ $t("Register") }}</router-link
              >
            </div>
            <br />

            <div>
              <router-link
                style="color: black"
                class="float-right mt-2"
                to="/forgot_password"
                >{{ $t("Forgot_Pass") }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

export default {
  data() {
    return {
      userNameControl: false,
      userPasswordControl: false,
      authError: false,
      errorMessage: null,
      data: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    getDentalVersionActive() {
      return this.$store.getters.getDentalVersionActive;
    },
  },
  methods: {
    handleInfoRequest(status, data) {
      if (status == 200) {
        this.$store.dispatch("setUserInfo", data);
      } else {
        this.$store.dispatch("setUserInfo");
      }
    },
    controlLogin(status) {
      this.$vs.loading.close();

      if (status == 200) {
        Api.get(API.BASEURL + API.ME, this.handleInfoRequest);
        this.authError = false;

        setTimeout(() => {
          this.$router.push("/").catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        }, 400);
      } else if (status == 400) {
        this.errorMessage = this.$t("Login_Message_1");
        this.authError = true;
      } else if (status == 500) {
        this.errorMessage = this.$t("Login_Message_2");
        this.authError = true;
      } else if (status == 0) {
        this.errorMessage = this.$t("Login_Message_3");
        this.authError = true;
      } else {
        this.errorMessage = this.$t("Login_Message_4");
        this.authError = true;
      }
    },

    sendLoginRequest() {
      if (this.data.username === "") {
        this.userNameControl = true;
        this.errorMessage = this.$t("Login_Message_5");
        this.authError = true;
        return;
      } else if (this.data.password === "") {
        this.userPasswordControl = true;
        this.userNameControl = false;
        this.errorMessage = this.$t("Login_Message_6");
        this.authError = true;
        return;
      }
      this.userNameControl = false;
      this.userPasswordControl = false;
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      Api.login(this.data, this.controlLogin);
    },
  },

  created() {
    this.$vs.loading({
      text: this.$t("Loading_Please_Wait"),
    });

    setTimeout(() => {
      if (
        localStorage.getItem("token") != null &&
        localStorage.getItem("token") != ""
      ) {
        this.$vs.loading.close();
        this.$router.push("/").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      } else {
        this.$vs.loading.close();
      }
    }, 100);
  },
};
</script>

<style lang="scss">
.bg-image-dental {
  background-image: url("/img/dental-login-bg.jpg");
  background-position: center;
  background-repeat: repeat;
}
.bg-image-kuyum {
  background-image: url("/img/kuyum-login-bg.jpg");
  background-position: center;
  background-repeat: repeat;
}
</style>
